// global styles shared across the entire site
import 'styles/global.css'

// core styles shared by all of react-notion-x (required)
import '@simpledotink/react-notion-x/src/styles.css'

// used for code syntax highlighting (optional)
import '@simpledotink/react-notion-x/src/code.css'

// used for collection views selector (optional)
// TODO: re-add if we enable collection view dropdowns
// import 'rc-dropdown/assets/index.css'
import 'rc-dropdown/assets/index.css'

// used for rendering equations (optional)
import 'katex/dist/katex.min.css'

// here we're bringing in any languages we want to support for
// syntax highlighting via Notion's Code block
import 'prismjs'
import 'prismjs/components/prism-markup'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-bash'

import React from 'react'
import { Analytics } from '@vercel/analytics/react'
// import Script from 'next/script'

export default function App({ Component, pageProps }) {
  return (
    <>
      {/* <Script src='https://js.stripe.com/v3'></Script> */}
      <Component {...pageProps} />
      <Analytics />
    </>
  )
}
